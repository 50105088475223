<template>
	<panel :show="show" @close="close">
		<template #title>
			Edit Note
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/undraw-noted.svg"
					class="panel-illustration"
				/>
				<span class="mb-4 font-roboto text-center">
					Edit the note for the current
					{{ isPage ? 'page' : 'call' }}.
				</span>
				<textarea
					v-model="content"
					required
					placeholder="Enter new note here..."
					class="resize-none px-1 py-2 border rounded focus:outline-none focus:shadow-outline mb-4 font-roboto"
				></textarea>
				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						:disabled="busy"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							v-if="busy"
							class="btn-icon fa-spin mr-2"
							:icon="['far', 'circle-notch']"
						/>
						<font-awesome-icon
							v-else
							class="btn-icon"
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import { mapActions } from 'vuex'
import PanelMixin from '@/mixins/PanelMixin'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the current authenticated user's data to add to the new note object.
		 *
		 * @return {Object}
		 */
		getUser() {
			const { id, first_name, last_name } = this.$store.state.auth.user

			return {
				id,
				first_name,
				last_name,
			}
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			this.busy = true

			try {
				const response = await this.$api
					.calls()
					.notes()
					.update(this.sid, this.note, this.content)

				this.onUpdateSuccess(response)

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}

			this.busy = false

			this.close()
		},

		/**
		 * Handle a call note being updated successfully.
		 *
		 * @param {SuccessResponse} response
		 * @return {void}
		 */
		onUpdateSuccess(response) {
			const updateNote = this.isPage
				? this.updatePageNote
				: this.updateCallNote

			const note = response.get('data', {})

			updateNote({ ...note })

			this.content = ''
		},

		...mapActions({
			updateCallNote: 'calls/updateNote',
			updatePageNote: 'pages/updateNote',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EditNotePanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Indicates if the note belongs to a call.
		 *
		 * @type {Boolean}
		 */
		isPage: {
			type: Boolean,
		},

		/**
		 * The call note to be edited.
		 *
		 * @type {String}
		 */
		note: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null,
		},

		/**
		 * The current call's SID for which to update the note.
		 *
		 * @type {String}
		 */
		sid: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the note object.
		 *
		 * @return {void}
		 */
		note() {
			if (!this.note) {
				return
			}

			this.content = this.note.content
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			content: '',
		}
	},
}
</script>
