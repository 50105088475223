<template>
	<tab-bar>
		<tab-bar-item
			name="message"
			:icon="['far', 'voicemail']"
			:current="active"
			:focused="focused"
			@blur.native="blur"
			@focus.native="focus('message')"
			@click.native="onClick('message')"
			@keydown.left.native="focusPrevious"
			@keydown.right.native="focusNext"
		>
			Message
		</tab-bar-item>
		<tab-bar-item
			name="history"
			:icon="['far', 'history']"
			:current="active"
			:focused="focused"
			@blur.native="blur"
			@focus.native="focus('history')"
			@click.native="onClick('history')"
			@keydown.left.native="focusPrevious"
			@keydown.right.native="focusNext"
		>
			History
		</tab-bar-item>
	</tab-bar>
</template>
<script>
import TabBar from './TabBar.vue'
import TabBarItem from './TabBarItem.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		TabBar,
		TabBarItem,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the tabs:activate event to the parent component.
		 *
		 * @param {String} name
		 * @return {VueComponent}
		 */
		activate(name) {
			this.$emit('activate', name)

			return this
		},

		/**
		 * Reset the focused tab when the focus leaves a tab item.
		 *
		 * @return {VueComponent}
		 */
		blur() {
			return this.focus('')
		},

		/**
		 * Set the current focused tab item.
		 *
		 * @param {String} name
		 * @return {VueComponent}
		 */
		focus(name) {
			this.focused = name

			return this
		},

		/**
		 * Set the focus to the next (or previous) tab item.
		 *
		 * @return {VueComponent}
		 */
		focusNext() {
			if (this.focused === 'message') {
				return this.focus('notes')
			}

			if (this.focused === 'notes') {
				return this.focus('history')
			}

			return this.focus('message')
		},

		/**
		 * Set the focus to the next (or previous) tab item.
		 *
		 * @return {VueComponent}
		 */
		focusPrevious() {
			if (this.focused === 'message') {
				return this.focus('history')
			}

			if (this.focused === 'history') {
				return this.focus('notes')
			}

			return this.focus('message')
		},

		/**
		 * Set the active & focused tab to the given tab name.
		 *
		 * @param {String} name
		 * @return {Promise}
		 */
		onClick(name) {
			return this.focus(name).activate(name)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CallTabs',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The currently active/displayed tab on the page.
		 *
		 * @type {String}
		 */
		active: {
			type: String,
			required: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			focused: '',
		}
	},
}
</script>
