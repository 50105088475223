<template>
	<panel :show="show" @close="close">
		<template #title>
			Add Note
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/undraw-noted.svg"
					class="panel-illustration"
				/>
				<span class="mb-4 font-roboto text-center">
					Add a new note to the current
					{{ isPage ? 'page' : 'call' }}.
				</span>

				<textarea
					v-model="content"
					required
					placeholder="Enter new note here..."
					class="resize-none px-1 py-2 border rounded focus:outline-none focus:shadow-outline mb-4 font-roboto"
				></textarea>

				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple" />

						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>

					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						:disabled="busy"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							v-if="busy"
							class="btn-icon fa-spin mr-2"
							:icon="['far', 'circle-notch']"
						/>

						<font-awesome-icon
							v-else
							class="btn-icon"
							:icon="['far', 'check']"
							aria-hidden="true"
						/>

						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current active call.
		 *
		 * @return {Object}
		 */
		call() {
			return this.isPage
				? this.findByPageSid(this.sid)
				: this.findByCallSid(this.sid)
		},

		...mapActions({
			findByCallSid: 'calls/findBySid',
			findByPageSid: 'pages/findBySid',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			this.busy = true

			try {
				const response = await this.$api
					.calls()
					.notes()
					.create(this.sid, this.content)

				this.onCreateSuccess(response)

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}

			this.busy = false

			this.close()
		},

		/**
		 * Handle a new call note being created successfully.
		 *
		 * @param {SuccessResponse} response
		 * @return {void}
		 */
		onCreateSuccess(response) {
			const note = { ...response.get('data', {}) }

			this.isPage ? this.addPageNote(note) : this.addCallNote(note)

			this.content = ''
		},

		...mapActions({
			addCallNote: 'calls/addNote',
			addPageNote: 'pages/addNote',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'AddNotePanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the call is a paging call.
		 *
		 * @type {Boolean}
		 */
		isPage: {
			type: Boolean,
		},

		/**
		 * The current call's SID to add the note to.
		 *
		 * @type {String}
		 */
		sid: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			content: '',
		}
	},
}
</script>
