import { render, staticRenderFns } from "./EditNotePanel.vue?vue&type=template&id=48f52be8&"
import script from "./EditNotePanel.vue?vue&type=script&lang=js&"
export * from "./EditNotePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports