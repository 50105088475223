<template>
	<panel :show="show" @close="close">
		<template #title>
			Delete Note
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/undraw-throw-away.svg"
					class="panel-illustration"
				/>
				<div>
					<div class="form-group">
						<span class="mb-4 font-roboto text-center">
							Are you sure you want to delete this note?
						</span>
					</div>
					<div class="flex justify-between">
						<button
							type="button"
							class="btn btn-danger w-50 mr-2"
							@click.prevent="close"
						>
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'times-octagon']"
							/>
							<span class="btn-label">Cancel</span>
						</button>
						<button
							ref="last"
							type="submit"
							class="btn btn-outline-success w-50 ml-2"
							:disabled="busy"
							@keydown.tab="onLastElementTab"
						>
							<div class="btn-ripple" />
							<font-awesome-icon
								v-if="busy"
								class="btn-icon fa-spin mr-2"
								:icon="['far', 'circle-notch']"
							/>
							<font-awesome-icon
								v-else
								class="btn-icon"
								:icon="['far', 'save']"
								aria-hidden="true"
							/>
							<span class="btn-label">Delete</span>
						</button>
					</div>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import { mapActions } from 'vuex'
import PanelMixin from '@/mixins/PanelMixin'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				this.busy = true

				const response = await this.$api
					.calls()
					.notes()
					.delete(this.sid, this.note)

				const deleteNote = this.isPage
					? this.deletePageNote
					: this.deleteCallNote

				await deleteNote({ ...this.note })

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}

			this.busy = false

			this.close()
		},

		...mapActions({
			deleteCallNote: 'calls/deleteNote',
			deletePageNote: 'pages/deleteNote',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'DeleteNotePanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Indicates if the note belongs to a call.
		 *
		 * @type {Boolean}
		 */
		isPage: {
			type: Boolean,
		},

		/**
		 * The call's note to be deleted.
		 *
		 * @type {String}
		 */
		note: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null,
		},

		/**
		 * The current call's SID from which to delete the note.
		 *
		 * @type {String}
		 */
		sid: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
		}
	},
}
</script>
